exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opinion-dewynters-digest-february-2025-edition-here-comes-the-pun-js": () => import("./../../../src/pages/opinion/dewynters-digest-february-2025-edition-here-comes-the-pun.js" /* webpackChunkName: "component---src-pages-opinion-dewynters-digest-february-2025-edition-here-comes-the-pun-js" */),
  "component---src-pages-opinion-index-js": () => import("./../../../src/pages/opinion/index.js" /* webpackChunkName: "component---src-pages-opinion-index-js" */),
  "component---src-pages-opinion-redefining-theatre-audiences-the-new-faces-of-frequent-theatregoers-js": () => import("./../../../src/pages/opinion/redefining-theatre-audiences-the-new-faces-of-frequent-theatregoers.js" /* webpackChunkName: "component---src-pages-opinion-redefining-theatre-audiences-the-new-faces-of-frequent-theatregoers-js" */),
  "component---src-pages-opinion-robertdewynter-js": () => import("./../../../src/pages/opinion/robertdewynter.js" /* webpackChunkName: "component---src-pages-opinion-robertdewynter-js" */),
  "component---src-pages-opinion-the-clock-might-be-ticking-on-tiktok-but-its-definitely-our-friend-for-now-js": () => import("./../../../src/pages/opinion/the-clock-might-be-ticking-on-tiktok-but-its-definitely-our-friend-for-now.js" /* webpackChunkName: "component---src-pages-opinion-the-clock-might-be-ticking-on-tiktok-but-its-definitely-our-friend-for-now-js" */),
  "component---src-pages-opinion-theatre-fans-refuse-to-take-a-bow-audiences-return-in-force-js": () => import("./../../../src/pages/opinion/theatre-fans-refuse-to-take-a-bow-audiences-return-in-force.js" /* webpackChunkName: "component---src-pages-opinion-theatre-fans-refuse-to-take-a-bow-audiences-return-in-force-js" */),
  "component---src-pages-opinion-truth-beauty-freedom-love-and-pride-js": () => import("./../../../src/pages/opinion/truth-beauty-freedom-love-and-pride.js" /* webpackChunkName: "component---src-pages-opinion-truth-beauty-freedom-love-and-pride-js" */),
  "component---src-pages-opinion-what-would-beyonce-do-js": () => import("./../../../src/pages/opinion/what-would-beyonce-do.js" /* webpackChunkName: "component---src-pages-opinion-what-would-beyonce-do-js" */),
  "component---src-pages-privacy-and-cookies-js": () => import("./../../../src/pages/privacy-and-cookies.js" /* webpackChunkName: "component---src-pages-privacy-and-cookies-js" */)
}

